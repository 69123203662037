import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  overrideUrl = true;
  errorData!: HttpErrorResponse;
  baseUrl = '';
  public headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('role', 'role=CP_PUBLIC');

  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public outsideShowSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  url1: string;
  url: string;
  constructor(private http: HttpClient) {
    /* url from config file or environments */
    this.url = environment.apiHost; 
    this.url1 = '';
  }

  get(apiRoute: string) {
    return this.http.get(`${this.url + apiRoute}`, {
      headers: this.getHttpHeaders()
    });
  }

  post(apiRoute: string, body: any) {
    return this.http.post(`${this.url + apiRoute}`, body, {
      headers: this.getHttpHeaders()
    });
  }

  put(apiRoute: string, body: any) {
    return this.http.put(`${this.url + apiRoute}`, body, {
      headers: this.getHttpHeaders()
    });
  }

  patch(apiRoute: string, body?: any) {
    return this.http.patch(`${this.url + apiRoute}`, body, {
      headers: this.getHttpHeaders()
    });
  }

  delete(apiRoute: string) {
    return this.http.delete(`${this.url + apiRoute}`, {
      headers: this.getHttpHeaders()
    });
  }

  getHttpHeaders(): HttpHeaders {
    return new HttpHeaders().set('key', 'value');
  }

  getAuthAccessKey(apiRoute, body) {
    return this.http.post(`${this.url + apiRoute}`, body, {
      headers: this.getHttpNewHeaders()
    });
  }

  getHttpNewHeaders(): HttpHeaders {
    return this.headers.set('Authorization', `Bearer ${this.getToken()}`);
  }

  getToken(): any {
    const token = 'jwt-token';
    return sessionStorage.getItem(token);
  }

  getAttachmentHttpHeaders(contentType): HttpHeaders {
    return new HttpHeaders().set('Content-Type', contentType).set('x-ms-blob-type', 'BlockBlob');
  }
  putUpload(apiRoute: string, body: any, contentType) {
    return this.http.put(`${apiRoute}`, body, { headers: this.getAttachmentHttpHeaders(contentType) });
  }

  putupload2(apiRoute: string, body: any, contenttype): Observable<any> {
    return this.http
      .put(`${this.url1 + apiRoute}`, body, {
        headers: this.getAttachmentHttpHeaders(contenttype),
        observe: 'response'
      })
      .pipe(
        map(data => data)
      );
  }
  getAutosuggest(url) {
    return this.http.get(`${url}`);
  }
  /**
   *
   * @param apiRoute
   * This function will download the stream file from the API service.
   * No HTTP required for this stream. So used Window.location.href to download the file
   */
  getFormDownloaded(apiRoute: string) {
    window.location.href = `${this.url + apiRoute}`;
  }
  /* common http service(optional) */

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      /* Client-side errors */
      errorMessage = `Error: ${error.error.message}`;
    } else {
      /* Server-side errors */
      errorMessage = `Error Code: ${error.status}\nMessage: ${
        error?.error?.message ? error?.error?.message : error.message
      }`;
    }
    return throwError(errorMessage);
  }
}
